import { useState } from 'react';
import Contact from '@components/Forms/Contact';
import { strapiMedia } from '@utils/helpers';

const Quotation = ({ bg, text, action, backdrop }) => {
    const [open, setOpen] = useState(false);
    return (
        <>
            <section
                className={`${bg} bg-cover bg-center h-18 md:h-30 w-full`}
                style={{ backgroundImage: `url("${strapiMedia(bg)}")` }}
            >
                <div className={`justify-center w-full px-7 p-2 ${backdrop} h-full sm:px-4`}>
                    <div className=" flex flex-col w-full items-center max-w-screen-lg mx-auto">
                        <p className="text-white text-[10px] md:text-xl my-2 md:my-8">{text}</p>
                        <button
                            type="button"
                            onClick={() => setOpen(true)}
                            className="text-white bg-primary-900 hover:bg-primary-700 focus:ring-4 focus:ring-primary-100 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 focus:outline-none"
                        >
                            {action}
                        </button>
                    </div>
                </div>
            </section>
            <Contact isOpen={open} setIsOpen={setOpen} />
        </>
    );
};

Quotation.defaultProps = {
    backdrop: 'backdrop-brightness-50',
    action: 'Contact Us'
};

export default Quotation;
