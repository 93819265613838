import Head from 'next/head';
import React, { useContext } from 'react';
import { StrapiContext } from '../../Context/StrapiContext';
import { strapiMedia } from '@utils/helpers';

const Seo = ({ meta, seo }) => {
    const { Meta, SiteName } = useContext(StrapiContext);

    const seoWithDefaults = meta
        ? {
              ...Meta,
              ...meta.attributes.Meta,
              MetaImage: {
                  data: { ...Meta['MetaImage'].data, ...meta.attributes.Meta['MetaImage'].data }
              }
          }
        : { ...Meta };

    const fullSeo = !seo
        ? {
              ...seoWithDefaults,
              metaTitle: `${seoWithDefaults['MetaTitle']} | ${SiteName}`,
              shareImage: strapiMedia(seoWithDefaults['MetaImage'])
          }
        : {
              ...Meta,
              metaTitle: `${seo.attributes.title} | ${SiteName}`,
              shareImage: strapiMedia(seo.attributes.image),
              metaDescription: seo.attributes.description,
              article: true
          };

    return (
        <Head>
            <title>{fullSeo.metaTitle}</title>
            {fullSeo.metaTitle && (
                <>
                    <meta property="og:title" content={fullSeo.metaTitle} />
                    <meta name="twitter:title" content={fullSeo.metaTitle} />
                </>
            )}
            {fullSeo['metaDescription'] && (
                <>
                    <meta name="description" content={fullSeo['MetaDescription']} />
                    <meta property="og:description" content={fullSeo['MetaDescription']} />
                    <meta name="twitter:description" content={fullSeo['MetaDescription']} />
                </>
            )}
            {fullSeo.shareImage && (
                <>
                    <meta property="og:image" content={fullSeo.shareImage} />
                    <meta name="twitter:image" content={fullSeo.shareImage} />
                    <meta name="image" content={fullSeo.shareImage} />
                </>
            )}
            {fullSeo.article && <meta property="og:type" content="article" />}
            <meta name="twitter:card" content="summary_large_image" />
        </Head>
    );
};

export { Seo };
