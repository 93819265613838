import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import SocialMedia from '@components/Hero/SocialMedia';
import { FiSend } from 'react-icons/fi';
import { Toast } from '@components/Toasts';
import toast from 'react-hot-toast';
import PropTypes from 'prop-types';
import { StrapiConnector } from '@config/Axios';

const Contact = ({ isOpen, setIsOpen, subject, receiver }) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        let data = {
            name,
            email,
            phoneNumber,
            message,
            subject,
            receiver
        };

        StrapiConnector.post('/ezforms/submit/', { token: '', formData: data })
            .then((res) => {
                if (res.status === 200) {
                    toast.success('Thanks For Contacting Frostan, We will respond shortly.', {
                        position: 'top-right',
                        className: 'shadow-xl'
                    });
                    setName('');
                    setEmail('');
                    setMessage('');
                    setPhoneNumber('');
                    setTimeout(() => {
                        setIsOpen(false);
                    }, 2500);
                }
            })
            .catch((e) => {
                console.log(e);
                toast.error('Failed to contact Frostan. Please try again.', {
                    position: 'top-right',
                    className: 'shadow-xl'
                });
            });
    };

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as={'form'} onClose={() => setIsOpen(false)} open={isOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-50" />
                </Transition.Child>
                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="flex flex-col text-white bg-[#0C1B36] rounded-2xl p-8 space-y-4">
                                <Dialog.Title as="h3" className="text-2xl leading-6 font-medium">
                                    Interested to know more?
                                </Dialog.Title>
                                <div className="mt-2 flex flex-col space-y-4">
                                    <div>
                                        <label className="text-white" htmlFor="name">
                                            Name <sup className="text-red-600">*</sup>
                                        </label>
                                        <input
                                            required
                                            value={name}
                                            id="name"
                                            type="text"
                                            onChange={(e) => setName(e.target.value)}
                                            className="w-full p-1 bg-transparent text-lg border-b-2 border-white outline-none opacity-70 focus:opacity-100 focus:border-white"
                                        />
                                    </div>
                                    <div>
                                        <label className="text-white" htmlFor="email">
                                            E-mail <sup className="text-red-600">*</sup>
                                        </label>
                                        <input
                                            value={email}
                                            id="email"
                                            type="email"
                                            required
                                            onChange={(e) => setEmail(e.target.value)}
                                            className="w-full p-1 text-white bg-transparent text-lg border-b-2 border-white outline-none opacity-70 focus:opacity-100 focus:border-white"
                                        />
                                    </div>

                                    <div>
                                        <label className="text-white" htmlFor="phone">
                                            Phone
                                        </label>
                                        <input
                                            value={phoneNumber}
                                            id="phone"
                                            type="text"
                                            onChange={(e) => setPhoneNumber(e.target.value)}
                                            className="w-full p-1 text-white bg-transparent text-lg border-b-2 border-white outline-none opacity-70 focus:opacity-100 focus:border-white"
                                        />
                                    </div>
                                    <div>
                                        <label className="text-white" htmlFor="mdg">
                                            Message <sup className="text-red-600">*</sup>
                                        </label>
                                        <textarea
                                            required
                                            value={message}
                                            id="msg"
                                            rows={3}
                                            onChange={(e) => setMessage(e.target.value)}
                                            className="w-full p-1 text-white bg-transparent text-lg border-b-2 border-white outline-none opacity-70 focus:opacity-100 focus:border-white"
                                        />
                                    </div>
                                    <div className="flex justify-between">
                                        <SocialMedia
                                            direction={'flex-row'}
                                            spacing={'space-x-1'}
                                            iconColor={'text-white'}
                                            iconSize={'h-7 w-7'}
                                        />
                                        <button
                                            onClick={handleSubmit}
                                            className="inline-flex items-center transition-all duration-500 p-1 px-4 text-white bg-gray-500 hover:bg-gray-100 hover:text-primary-900 rounded-xl opacity-50 hover:opacity-100"
                                        >
                                            <FiSend className="mr-1" />
                                            Send
                                        </button>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
                <Toast />
            </Dialog>
        </Transition>
    );
};

Contact.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    setIsOpen: PropTypes.func.isRequired,
    subject: PropTypes.string,
    receiver: PropTypes.string
};

Contact.defaultProps = {
    subject: 'Quotation request from Corporate Site',
    receiver: 'sales@frostan.co.tz'
};

export default Contact;
